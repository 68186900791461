import UTMGenerator from "./components/organisms/UTMGenerator";
import "./styles/global.css";

function App() {
  return (
    <div className="App">
      <UTMGenerator />
    </div>
  );
}

export default App;
