import styled from "styled-components";

export const TextFieldContainer = styled.input`
  display: flex;
  flex-direction: column;
  border: none;
  width: 100%;
  height: 100%;

  :focus {
    outline: none;
  }
`;
