import { InputHTMLAttributes } from "react";
import { DropdownContainer } from "./Dropdown.styled";

interface DropdownProps extends InputHTMLAttributes<HTMLSelectElement> {
  children?: JSX.Element | JSX.Element[];
}

const Dropdown = (props: DropdownProps) => {
  const { children, ...inputProps } = props;

  return (
    <DropdownContainer {...inputProps}>{props.children}</DropdownContainer>
  );
};

export default Dropdown;
