import React, { useEffect, useState } from "react";
import * as Styled from "./QuoteOfTheDay.styled";
import axios from "axios";

interface QuoteOfTheDayProps {}

const QuoteOfTheDay = (props: QuoteOfTheDayProps) => {
  const [quote, setQuote] = useState<
    { author: string; category: string; quote: string } | undefined
  >(undefined);

  const fetchQuote = async () => {
    try {
      const category = "design";
      const data = await axios.get(
        `https://api.api-ninjas.com/v1/quotes?category=${category}`,
        {
          headers: { "X-Api-Key": "ZOxiIjYokgn2++Q/FwF6Mw==etroChOXIeJSCOxB" },
        }
      );
      let quotes = data.data;
      if (quotes.length === 0) {
        return;
      }
      const quote = quotes[0];
      setQuote(quote);
    } catch (e) {}
  };

  useEffect(() => {
    fetchQuote();
  }, []);

  if (!quote) {
    return null;
  }

  return (
    <Styled.QuoteOfTheDayContainer>
      <p style={{ fontStyle: "italic", color: "grey" }}>
        "{quote.quote}" - {quote.author}
      </p>
    </Styled.QuoteOfTheDayContainer>
  );
};

export default QuoteOfTheDay;
