import { useState } from "react";
import GeneratedURLCard from "../GeneratedURLCard";
import UTMForm from "../UTMForm";
import { UTMGeneratorContainer } from "./UTMGenerator.styled";
import QuoteOfTheDay from "../QuoteOfTheDay";

interface UTMGeneratorProps {}

function fixedEncodeURIComponent(str: string) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
}

const isValidUrl = (urlString: string) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

const UTMGenerator = (props: UTMGeneratorProps) => {
  const [generatedUrl, setGeneratedUrl] = useState<string | undefined>(
    undefined
  );

  const displayURL = () => {
    if (!generatedUrl) {
      return `Enter a valid URL`;
    }
  };

  const handleSubmit = (values: any) => {
    let url = values.websiteUrl;

    // if (!url || !isValidUrl(url)) {
    //   setGeneratedUrl(undefined);
    //   return;
    // }

    let setFlag = false;
    Object.keys(values).forEach((key) => {
      if (key === "websiteUrl" || values[key] === "") {
        return;
      }

      let value = String(values[key]);
      value = fixedEncodeURIComponent(value);
      console.log(`set flag = ${setFlag}`);
      // parsedUrl.searchParams.set(key, value);
      url += `${setFlag ? `&` : "?"}${key}=${value}`;
      setFlag = true;
    });

    setGeneratedUrl(url);
  };

  return (
    <UTMGeneratorContainer>
      <UTMForm
        onSubmit={handleSubmit}
        onChange={(values) => {
          handleSubmit(values);
        }}
      />
      {generatedUrl ? (
        <>
          {!isValidUrl(generatedUrl) ? (
            <p style={{ color: "red", fontSize: 20, fontWeight: "bold" }}>
              Please double check that this link works in your browser
            </p>
          ) : null}
          <GeneratedURLCard url={generatedUrl} />
        </>
      ) : null}
      <QuoteOfTheDay />
    </UTMGeneratorContainer>
  );
};

export default UTMGenerator;
