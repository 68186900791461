import CopyIcon from "../../atoms/Icon/copy";
import { Header1, Header2 } from "../../atoms/Typography/Titles";

import {
  GeneratedURLCardContainer,
  IconWrapper,
  LinkWrapper,
  TitleLabel,
} from "./GeneratedURLCard.styled";

interface GeneratedURLCardProps {
  url?: string;
}

function copyToClipboard(copyText: string) {
  // Get the text field

  // Select the text field

  // Copy the text inside the text field
  if (navigator && navigator.clipboard) {
    navigator.clipboard.writeText(copyText);
  }
}

const GeneratedURLCard = (props: GeneratedURLCardProps) => {
  console.log(`Generated Prop URL = ${props.url}`);
  return (
    <GeneratedURLCardContainer>
      <Header2 style={{ marginBottom: 10 }}>Generated Campaign URL</Header2>
      <LinkWrapper>
        <IconWrapper
          onClick={() => {
            if (!props.url) {
              alert("Invalid URL");
              return;
            }
            copyToClipboard(props.url);
          }}
        >
          <CopyIcon />
        </IconWrapper>
        <TitleLabel>Generated URl</TitleLabel>
        <input
          readOnly
          value={props.url}
          style={{ color: "grey", border: "none", flex: "1" }}
        />
      </LinkWrapper>
    </GeneratedURLCardContainer>
  );
};

export default GeneratedURLCard;
