import styled from "styled-components";

export const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
  border: solid grey 1px;
  border-radius: 3px;

  :hover {
    border: solid blue 1px;
  }
`;

export const TitleLabel = styled.label`
  color: grey;
  font-size: 12px;
  position: absolute;
  top: -7px;
  left: 20px;
  background: white;
`;

export const InfoI = styled.span`
  width: 14px;
  display: inline-block;
  height: 14px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  border: solid grey 1px;
  border-radius: 50%;
`;
