import styled from "styled-components";

export const LightboxContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  background: #000000cc;
  width: 100%;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 200;
`;

export const LightboxInner = styled.div`
  background: white;
`;
