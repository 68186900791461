import { Field, FieldProps, useField } from "formik";
import { InputHTMLAttributes, useState } from "react";
import Dropdown from "../../atoms/Dropdown";
import TextField from "../../atoms/TextField";
import Lightbox from "../Lightbox";
import { FormFieldContainer, InfoI, TitleLabel } from "./FormField.styled";

interface FormFieldProps {
  title?: string;
  type: "textfield" | "dropdown";
  inputProps?:
    | InputHTMLAttributes<HTMLInputElement>
    | InputHTMLAttributes<HTMLSelectElement>;
  values?: { name: string; value: string }[];
  info?: { title: string; text: JSX.Element };
}

const FormField = (props: FormFieldProps) => {
  const [showingInfo, setShowingInfo] = useState(false);

  return (
    <>
      <Lightbox showing={showingInfo} setShowing={setShowingInfo}>
        {props.info?.text}
      </Lightbox>
      <FormFieldContainer>
        {props.title ? (
          <TitleLabel>
            {props.title}
            {props.info ? (
              <InfoI onClick={() => setShowingInfo(true)}>i</InfoI>
            ) : null}
          </TitleLabel>
        ) : null}
        <Field name={props.inputProps!.name}>
          {(fieldProps: FieldProps) => (
            <>
              {props.type === "textfield" ? (
                <TextField {...fieldProps.field} />
              ) : null}
              {props.type === "dropdown" ? (
                <Dropdown {...fieldProps.field}>
                  {props.values?.map((node) => (
                    <option key={node.value} value={node.value}>
                      {node.name}
                    </option>
                  ))}
                </Dropdown>
              ) : null}
            </>
          )}
        </Field>
      </FormFieldContainer>
    </>
  );
};

export default FormField;
