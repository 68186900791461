import { useState } from "react";
import { LightboxContainer, LightboxInner } from "./Lightbox.styled";

interface LightboxProps {
  children?: JSX.Element | JSX.Element[];
  showing: boolean;
  setShowing: (state: boolean) => void;
}

const Lightbox = (props: LightboxProps) => {
  return props.showing ? (
    <>
      <LightboxContainer onClick={() => props.setShowing(false)}>
        <LightboxInner onClick={(e) => e.stopPropagation()}>
          {props.children}
        </LightboxInner>
      </LightboxContainer>
    </>
  ) : null;
};

export default Lightbox;
