import styled from "styled-components";

export const UTMFormContainer = styled.div`
  display: flex;
`;

export const TextFieldWrapper = styled.div<{ columns: number }>`
  flex: 0 1 ${(props) => props.columns * 10}%;
  margin: 10px 0;

  @media (max-width: 676px) {
    flex: 0 0 100%;
  }
`;

export const InnerFormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ExampleImage = styled.img`
  width: 800px;
  height: auto;
`;

export const InfoWrapper = styled.div`
  padding: 10px;
`;

export const SubmitButton = styled.div``;
