import styled from "styled-components";

export const Header1 = styled.h1`
  margin: 0;
`;

export const Header2 = styled.h2`
  font-weight: 500;
  margin: 0;
`;

export const Header3 = styled.h3``;
