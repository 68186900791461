import { Form, Formik, useFormik, useFormikContext } from "formik";
import { useEffect } from "react";
import { Header1, Header2 } from "../../atoms/Typography/Titles";
import FormField from "../../molecules/FormField";
import {
  ExampleImage,
  InfoWrapper,
  InnerFormWrapper,
  SubmitButton,
  TextFieldWrapper,
  UTMFormContainer,
} from "./UTMForm.styled";

interface UTMFormProps {
  onSubmit: (values: any) => void;
  onChange: (values: any) => void;
}

interface FormItem {
  key: string;
  title?: string;
  columns?: number;
  type: "textfield" | "dropdown";
  placeholder?: string;
  getValues?: () => { name: string; value: string }[];
  info?: {
    title: string;
    text: JSX.Element;
  };
}

interface FormSchema {
  items: FormItem[];
}

const UTMFormSchema: FormSchema = {
  items: [
    {
      key: "websiteUrl",
      title: "Website URL*",
      type: "textfield",
      placeholder: "Website URL",
    },
    {
      key: "utm_source",
      title: "Campaign Source*",
      type: "dropdown",
      getValues: () => {
        return [
          { name: "Hub Spot", value: "hubspot" },
          { name: "Constant Contact", value: "constantcontact" },
          { name: "Mail Chimp", value: "mailchimp" },
          { name: "SD Marketing (Reconeyez Only)", value: "sdmarketing" },
          {
            name: "HighLevel",
            value: "high_level",
          },
          { name: "Brochure", value: "brochure" },
          { name: "QR Code", value: "qr_code" },
          { name: "Youtube", value: "youtube" },
          { name: "Facebook", value: "facebook" },
          { name: "Google", value: "google" },
          { name: "Linked In", value: "linked_in" },
          { name: "Twitter", value: "twitter" },
        ];
      },
    },
    {
      key: "utm_medium",
      title: "Campaign Medium*",
      type: "dropdown",
      getValues: () => {
        return [
          {
            name: "Email",
            value: "email",
          },
          {
            name: "Printed Material",
            value: "print",
          },
          {
            name: "Advertisement",
            value: "advertisement",
          },
          {
            name: "Social Post",
            value: "social_post",
          },
        ];
      },
    },
    {
      key: "utm_campaign",
      title: "Campaign Name",
      type: "textfield",
    },
    {
      key: "utm_id",
      title: "Campaign ID",
      type: "textfield",
      info: {
        title: "Finding the Campaign ID",
        text: (
          <InfoWrapper>
            <Header2>Constant Contact</Header2>
            <p>
              1. Visit the Campaign Details page inside the Constant Contact
              account.
            </p>
            <p>
              2. In the URL bar, copy the sequence of letters, numbers, and
              dashes after "/activity/..."
            </p>
            <ExampleImage
              src="/assets/images/cc-id-example.png"
              alt="cc example"
            />
          </InfoWrapper>
        ),
      },
    },
    {
      key: "utm_term",
      title: "Campaign Term(s)",
      type: "textfield",
    },
    {
      key: "utm_content",
      title: "Campaign Content",
      type: "textfield",
    },
  ],
};

const generateInitialValues = (schema: FormSchema) => {
  const values: { [key: string]: string } = {};
  schema.items.forEach((item) => {
    const key = item.key;
    values[key] = "";
  });

  return values;
};

const FormObserver = (props: { onChange: (values: any) => void }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    props.onChange(values);
  }, [values]);

  return null;
};

const UTMForm = (props: UTMFormProps) => {
  const initialValues = generateInitialValues(UTMFormSchema);

  return (
    <UTMFormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => props.onSubmit(values)}
      >
        {(formikBag) => (
          <Form style={{ width: "100%", height: "100%" }}>
            <FormObserver onChange={props.onChange} />
            <InnerFormWrapper>
              {UTMFormSchema.items.map((item) => (
                <TextFieldWrapper columns={item.columns ?? 10} key={item.key}>
                  <FormField
                    type={item.type}
                    title={item.title}
                    inputProps={{
                      name: item.key,
                      placeholder: item.placeholder,
                    }}
                    values={
                      item.getValues
                        ? [{ name: "", value: "" }, ...item.getValues()]
                        : undefined
                    }
                    info={item.info}
                  />
                </TextFieldWrapper>
              ))}
            </InnerFormWrapper>
            {/* <SubmitButton>Submit</SubmitButton> */}
          </Form>
        )}
      </Formik>
    </UTMFormContainer>
  );
};

export default UTMForm;
