import styled from "styled-components";

export const GeneratedURLCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px 10px;
  border-radius: 2px;

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  padding: 10px;
  border: solid grey 1px;
  border-radius: 3px;

  :hover {
    border: solid blue 1px;
  }
`;

export const TitleLabel = styled.label`
  color: grey;
  font-size: 12px;
  position: absolute;
  top: -7px;
  left: 20px;
  background: white;
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;

  :hover {
    cursor: pointer;
    path {
      fill: blue;
    }
  }
`;
