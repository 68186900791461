import styled from "styled-components";

export const DropdownContainer = styled.select`
  display: flex;
  flex-direction: column;

  border: none;
  width: 100%;
  height: 100%;
`;
