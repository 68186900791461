import { ComponentProps, InputHTMLAttributes } from "react";
import { TextFieldContainer } from "./TextField.styled";

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {}

const TextField = (props: TextFieldProps) => {
  return <TextFieldContainer {...props} type="text" />;
};

export default TextField;
